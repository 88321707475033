import shopPageDom from "../Dom/ShopPageDom";

class ShopPageRouting {

    /**
     *
     * @param {object} params
     * @param {number} page
     * @returns {string}
     */
    generateUrl(params, page = 1){
        let paramsURI = '';
        let url = Routing.generate(`${ROUTE_NAME}.${LOCALE}`);
        let apiUrl = Routing.generate(`${ROUTE_NAME.replace('site', 'site_api')}.${LOCALE}`);

        if(params['categories'].length === 0) {
            params['categories'].push(MAIN_CATEGORY.slug);
        }

        for (let paramName in params) {
            if (params[paramName].length === 0) {
                continue;
            }

            paramsURI +=`/${Translator.trans(paramName, null, 'messages', LOCALE)}/${params[paramName].join('+')}`;
        }

        url += `${paramsURI}`;
        apiUrl += `/${page}${paramsURI}`;

        window.history.pushState({ path: url }, '', url);

        return apiUrl;
    }
}

export default ShopPageRouting;
