// import DateTimePicker from "../Inputs/DateTimePicker";

class AppHelperService {

    static isArray(data){
        return Object.prototype.toString.call(data) === '[object Array]';
    };

    static isObject(data){
        return Object.prototype.toString.call(data) === '[object Object]';
    };

    static isBoolean(data){
        return Object.prototype.toString.call(data) === '[object Boolean]';
    };

    static isString(data){
        return Object.prototype.toString.call(data) === '[object String]';
    };

    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    static redirect(href) {
        if(href == 'reload') {
            window.location.reload();
        } else{
            window.location.href = href;
        }
    };

    static generateLocalizedUrl(url, data) {
        return Routing.generate(url, data);
    }

    /**
     * @param {number} price
     * @param {string} locale
     * @returns {string}
     */
    static formatPrice(price, locale = DEFAULT_LOCALE) {
        let currencyDisplay = 'code';

        if (DEFAULT_LOCALE !== locale) {
            currencyDisplay = 'symbol';
        }

        const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: LANGUAGES[locale].currencyCode, currencyDisplay });

        return formatter.format(price);
    }

    /**
     * @param {number} price
     * @param {number} currencyRate
     * @param {boolean} isMultipliable
     * @returns {number|*}
     */
    static convertPrice(price, currencyRate = CURRENCY_RATE, isMultipliable = false)
    {
        price = AppHelperService.convertToAbsolutePrice(price, currencyRate, isMultipliable);

        return  price / 100;
    }

    /**
     * @param {number} price
     * @param {number} currencyRate
     * @param {boolean} isMultipliable
     * @returns {number|*}
     */
    static convertToAbsolutePrice(price, currencyRate = CURRENCY_RATE, isMultipliable = false)
    {
        if (1 === currencyRate) {
            return price;
        }

        if (true === isMultipliable) {
            return Math.ceil(price * currencyRate);
        }

        return Math.ceil(price / currencyRate);
    }

    /**
     * @param {number} price
     * @param {number} currencyRate
     * @param {string} locale
     * @param {boolean} isMultipliable
     * @returns {string}
     */
    static formatAndConvertPrice(price, currencyRate = CURRENCY_RATE, locale = LOCALE, isMultipliable = false)
    {
        price = this.convertPrice(price, currencyRate, isMultipliable);

        return this.formatPrice(price, locale);
    }

    /**
     * @param {number} price
     * @param {string} locale
     * @returns {string}
     */
    static formatDecimal(price, locale = LOCALE)
    {
        return price.toLocaleString(locale, {minimumFractionDigits: 2, maximumFractionDigits: 2, currencySign: 'accounting'});
    }

    /**
     * @param {number} price
     * @param {number} discount
     * @returns {number}
     */
    static calculatePercentage(price, discount)
    {
        return ((100 - (discount/price) * 100))
    }
};

export default AppHelperService;
