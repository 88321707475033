require('lightslider');

class SliderService {
    static setProductSlider() {

        const options = {
            item:1,
            loop: true,
        };

        if(IS_DESKTOP) {
            options.gallery = true;
            options.vertical = true;
            options.verticalHeight = 600;
            options.vThumbWidth = 100;
            options.thumbItem = 5;
            options.thumbMargin = 10;
            options.slideMargin = 0;
        }

        $('#slides').lightSlider(options);
    }
}

export default SliderService;
