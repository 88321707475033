import PriceHelperService from "../../../js/Helper/PriceHelperService";

class CartCalculatorService {
    #priceService;

    constructor() {
        this.#priceService = PriceHelperService;
    }

    calculate(productList, countryId, payment) {
        let total = 0;
        let shippingPrice = this.getShippingPrice(countryId, payment);
        const freeShippingPrice = this.getCountryCodeById(countryId) !== DEFAULT_LOCALE ? SITE_SETTINGS['FREE_SHIPPING_ABROAD'] : SITE_SETTINGS['FREE_SHIPPING'];

        $.each(productList, (index,elm) => {
            const discount = parseInt(elm.data('itemDiscount'));
            const promotionEligibleDiscountedItem = window.DISCOUNT_ELIGIBLE_DISCOUNTED_ITEMS;
            let itemTotal = parseInt(elm.data('total'));

            if (0 < window.DISCOUNT) {
                if (1 === promotionEligibleDiscountedItem && 0 !== discount) {
                    itemTotal = Math.round(itemTotal - (itemTotal * (window.DISCOUNT/100)));
                } else if (0 === discount) {
                    itemTotal = Math.round(itemTotal - (itemTotal * (window.DISCOUNT/100)));
                }
            }

            // if (0 === discount && 0 < window.DISCOUNT) {
            //     itemTotal = Math.round(itemTotal - (itemTotal * (window.DISCOUNT/100)));
            // }

            total += itemTotal;
        });

        total = Math.round(total);

        // if (window.DISCOUNT > 0) {
        //     total = Math.round(total - (total * (window.DISCOUNT/100)));
        // }

        if (null !== shippingPrice) {
            if (total/100 < freeShippingPrice ) {
                total += shippingPrice;
            } else {
                shippingPrice = 0;
            }
        }

        return {
            total: this.#priceService.convertPriceWithCurrency(total),
            shippingPrice: this.#priceService.convertPriceWithCurrency(shippingPrice),
        };
    }

    getShippingPrice(country, payment) {
        for (let i = 0; i < SHIPPINGS.length; i++) {
            let shipping = SHIPPINGS[i];

            if (shipping.countries.includes(country) && shipping.payments.includes(payment)) {
                return shipping.price;
            }
        }

        return 0;
    }

    /**
     * @param {number} countryId
     *
     * @returns {null|string}
     */
    getCountryCodeById(countryId)
    {
        for(const country of COUNTRIES) {
            if (country.value == countryId) {
                return country.country_code;
            }
        }

        return null
    }
}

export default CartCalculatorService;
