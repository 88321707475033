import filterMapper from "../../Mapper/FilterMapper";

class FilterDomService {
    constructor() {
        this.mapper = filterMapper;
    }

    addSelectedFilter(name, value, text, onlyOne = false, isMobile = false)
    {
        if (true === onlyOne) {
            this.removeAllCriteriaOnPageByName(name);
        }

        const criteria = `<a class="btn selected-filter-btn letter-capitalize" data-name="${name}" data-value="${value}" data-is-draft="1">${text}<span class="close"></span></a>`;

        $(this.mapper.searchView).append(criteria);

        if (true === isMobile) {
            $(this.mapper.selectedCriteriaFilter).append(criteria);
        }

        if (false === $(`[data-name="${name}"][data-search="${value}"]`, $(this.mapper.filterWidget)).hasClass('active')) {
            $(`[data-search-name="${name}"][data-search="${value}"]`, $(this.mapper.filterWidget)).addClass('active');

            if (true === isMobile) {
                $(`[data-search-name="${name}"][data-search="${value}"]`, $(this.mapper.filterWidgetMobile)).addClass('active');
            }
        }
    }

    totalParamsOnFilter(parentElm, total)
    {
        $(this.mapper.totalSelectedFilter, parentElm)
            .text(total > 0 ? `(${total})` : '');
    }

    resetTotalParamsOnFilter()
    {
        $(this.mapper.totalSelectedFilter).each(index => {
            $(this.mapper.totalSelectedFilter).eq(index).empty();
        })
    }

    /**
     * @param {string} filterName
     * @param {string|number} paramValue
     */
    removeFilter(filterName, paramValue)
    {
        $(`[data-name="${filterName}"][data-value="${paramValue}"]`).remove();
        $(`[data-search-name="${filterName}"][data-search="${paramValue}"]`, $(this.mapper.filterWidget)).removeClass('active');
        $(`[data-search-name="${filterName}"][data-search="${paramValue}"]`, $(this.mapper.filterWidgetMobile)).removeClass('active');
    }

    removeAllCriteriaOnPageByName(name)
    {
        $(`[data-name=${name}]`).remove();
        $(`[data-search-name="${name}"]`).removeClass('active');
    }
}

export default FilterDomService;
