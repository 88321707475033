import * as Sentry from '@sentry/browser';
import {BrowserTracing} from "@sentry/tracing";

if ('dev' !== APP_ENV) {
    Sentry.init({
        dsn: SENTRY_DSN,
        tunnel: '/api/sentry-js-proxy',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: SENTRY_ENV,
        release: SENTRY_ENV,
    });
}
