import cookieConsentMapper from "../../Mapper/CookieConsentMapper";
import cookieManipulationService from "./CookieManipulationService";

class CookieConsent {
    constructor() {
        this.mapper = cookieConsentMapper;
        this.manipulator = cookieManipulationService;
        this.settings = CookieConsent.settings;

        this.registerEvents();
    }

    init() {
        const cookie = this.manipulator.getCookie(this.settings.name);

        if (null !== cookie) {
            return;
        }

        setTimeout(() => {
            this.openModal();
        }, 2000);
    }

    openModal() {
        const selectedCookies = this.manipulator.getCookie(this.settings.name);

        if (selectedCookies && selectedCookies.hasOwnProperty('analytics')) {
            $(this.mapper.chkAnalytics).prop('checked', true);
        }

        $(this.mapper.modal).modal('show');
    }

    registerEvents() {
        $(this.mapper.acceptBtn).on('click', e => {
            const cookieCheckboxes = {'required': 1};

            if ($(this.mapper.chkAnalytics).is(':checked')) {
                cookieCheckboxes.analytics = 1;
            }

            this.manipulator.setCookie(
                this.settings.name,
                this.settings.expires,
                JSON.stringify(cookieCheckboxes)
            );

            $(this.mapper.modal).modal('hide');
        });
    }

    static get settings() {
        return Object.freeze({
            name: 'cookieConsent',
            expires: 30
        });
    }
}

export default CookieConsent;
