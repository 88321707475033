import shopPageDom from "../Dom/ShopPageDom";
import paginationDom from "../Dom/PaginationDom";
import coreMapper from "../Mapper/CoreMapper";
import shopPageMapper from "../Mapper/ShopPageMapper";
import filterParamService from "./Filter/FilterParamService";
import ShopPageRouting from "../Routing/ShopPageRouting";

class ShopPageService {
    #dom;
    #mapper;
    #pagination;
    #coreMapper;
    #paramService;
    #router;
    #currentPage = 1;
    #loadPageFinished = true;
    #disableScroll = false;
    #timeoutId = undefined;
    
    constructor() {
        this.#dom = shopPageDom;
        this.#mapper = shopPageMapper;
        this.#pagination = paginationDom;
        this.#coreMapper = coreMapper;
        this.#paramService = filterParamService;
        this.#router = new ShopPageRouting();

        let selectedPrices = [0, PRICES[1]];

        if (SEARCH_CRITERIA.hasOwnProperty('price')) {
            selectedPrices = SEARCH_CRITERIA.price[0].split('-');
        }

        $.scrollUp.init({
            scrollTrigger: $('<a/>', {
                id: 'scroll-to-products',
                href: '#products',
                class: 'hide',
            }),
            scrollTarget: 50,
            easingType: 'linear',
            scrollSpeed: 1500,
            animation: 'fade'
        });

        this.#mapper.priceRange.slider({
            range: true,
            min: 0,
            max: PRICES[1],
            values: selectedPrices,
            slide: (event, ui) => {
                this.#mapper.amountPrice.val(`${ui.values[0]} ${CURRENT_CURRENCY_CODE} - ${ui.values[1]} ${CURRENT_CURRENCY_CODE}`);
            }
        });
        this.#mapper.amountPrice.val(`${this.#mapper.priceRange.slider("values", 0)} ${CURRENT_CURRENCY_CODE} - ${this.#mapper.priceRange.slider("values", 1)} ${CURRENT_CURRENCY_CODE}`);
    }

    generateLoadItemsUrl(page = null) {
        this.#currentPage++;

        if (null !== page) {
            this.#currentPage = page;
        }

        return this.#router.generateUrl(this.#paramService.getParams(), this.#currentPage);
    }

    loadItems(page = null, showLoader = true) {
        const apiUrl = this.generateLoadItemsUrl(page);

        return this.#applyFilter(apiUrl, null === page, showLoader);
    }

    loadOnPageScroll()
    {
        if (true === this.#disableScroll) {
            return;
        }

        const documentHeight = $(document).height();
        const footerHeight = Math.floor($('.footer-top-area').height() + $('.footer-bottom-area').height());
        const scrollTopPosition = $(window).scrollTop();
        const scrollPos = Math.floor($(window).height() + scrollTopPosition);
        const shouldTriggerAjax = documentHeight * 0.6 < scrollPos;

        if (typeof this.#timeoutId !== undefined) {
            clearTimeout(this.#timeoutId);
        }

        if (documentHeight - footerHeight <= scrollPos) {
            this.#disableScroll = true;

            return;
        }

        this.#timeoutId = setTimeout(() => {
            if (this.#loadPageFinished && shouldTriggerAjax) {
                this.#loadPageFinished = false;

                const loadItemsPromise = this.loadItems(null, false);

                loadItemsPromise.then(() => {
                    this.#loadPageFinished = true;
                });
            }
        }, 100);
    }

    /**
     *
     * @param {string} url
     * @param {boolean} appendItemsToList
     * @param {boolean} showLoader
     * @returns {Promise}
     */
    #applyFilter(url, appendItemsToList = false, showLoader = true)
    {
        return new Promise((resolve, reject) => {

            if (true === showLoader) {
                $('#page-loader').fadeOut('slow', function () {
                    $(this).removeClass('hide');
                });
            }

            $.ajax({
                type: 'GET',
                url: url,
                dataType: 'json',
                success: response => {
                    this.#renderResponse(response, appendItemsToList);

                    this.#disableScroll = false;

                    resolve(true);
                },
                error: error => {
                    reject(true);
                }
            })
        });
    }

    #renderResponse(response, appendItemsToList)
    {
        const itemListDom = $('.grid-items > .row');
        const paginationData = response.products.pagination;

        if (false === appendItemsToList) {
            itemListDom.empty();
            $('#scroll-to-products').trigger('click');
        }

        itemListDom.append(this.#dom.generateProducts(response));

        $('#page-loader').addClass('hide');

        if (paginationData.currentPage >= paginationData.totalPages) {
            this.#dom.hideLoadMore()
        }

        for(const [locale, url] of Object.entries(response.localized_urls)) {
            let key = `lang_link_${locale}`;
            $(`${this.#coreMapper[key]}`).attr('href', url);
        }

        this.#dom.generateBreadcrumbs(response.breadcrumbs);
    }
}

export default ShopPageService;
