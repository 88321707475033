import cartPageMapper from "../Mapper/CartPageMapper";
import CartHandler from "../Handler/CartHandler";

require ('select2/dist/js/select2.full');

class CartPageController {
    constructor() {
        this.mapper = cartPageMapper;
        this.hander = new CartHandler();

        this.registerEvents();

        $(this.mapper.countryOptions).select2({
            minimumResultsForSearch: -1
        });


        $(this.mapper.paymentOptions).select2({
            minimumResultsForSearch: -1
        });
    }

    registerEvents() {
        this.mapper.updateBtn.on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.hander.update();
        });

        this.mapper.removeProduct.on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            const id = $(e.currentTarget).data('id');

            this.hander.remove(id, $(e.currentTarget).closest('tr'));
        })

        // this.mapper.promoCouponBtn.on('click', e => {
        //     e.preventDefault();
        //     e.stopPropagation();
        //
        //     this.hander.setCoupon()
        //         .then(response => {
        //             this.shippingService.updateProductsPrices();
        //         })
        // });

        // $(this.mapper.countryOptions).on('change', e => {
        //     $(this.mapper.paymentOptions).val('-1');
        //     this.shippingService.updateOptions($(this.mapper.paymentOptions));
        //     this.shippingService.setAvailablePayments();
        //     this.shippingService.updateProductsPrices();
        // });

        // $(this.mapper.paymentOptions).on('change', e => {
        //     this.shippingService.updateProductsPrices();
        // });
    }
}

export default CartPageController
