require('@fancyapps/fancybox');

class CatalogPageController {
    constructor() {
        this.registerEvents();
    }

    registerEvents() {}
}

export default CatalogPageController;