import filterMapper from "../../Mapper/FilterMapper";
import filterParamService from "./FilterParamService";
import shopPageDom from "../../Dom/ShopPageDom";

class FilterService {
    #router;
    #mapper;
    #paramService;
    #pageService;
    #dom;

    constructor(router, pageService) {
        this.#router = router;
        this.#mapper = filterMapper;
        this.#paramService = filterParamService;
        this.#pageService = pageService;
        this.#dom = shopPageDom;

        this.#paramService.setInitParams();

        this.registerEvents();
    }

    showSelectedFiltersOnLoad(searchCriteria, onlyOneFilterAtTime)
    {
        for (const [filter, values] of Object.entries(searchCriteria)) {
            this.#paramService.addParams(filter, values, window.isMobile, onlyOneFilterAtTime.indexOf(filter) > -1);
        }

        const totalFilters = this.#paramService.getTotalCountFilters();

        $(this.#mapper.totalFilterCount).text(totalFilters > 0 ? `(${totalFilters})` : '');
    }

    registerEvents() {
        $(this.#mapper.optionBtn).on('click', e => {
            $(e.currentTarget).toggleClass('active');
        });

        $(this.#mapper.category).on('click', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, elm.dataset.searchName, elm.dataset.search, elm.dataset.title, true, window.isMobile);
        });

        $(this.#mapper.color).on('click', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'color', elm.dataset.search, elm.dataset.title, false, window.isMobile);
        });

        $(this.#mapper.size).on('click', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'size', elm.dataset.search, elm.dataset.title, false, window.isMobile);
        });

        $(this.#mapper.discount).on('click', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'discounts', elm.dataset.search, elm.dataset.title, false, window.isMobile);
        });

        $(this.#mapper.heel).on('click', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'heels', elm.dataset.search, elm.dataset.title, false, window.isMobile);
        });

        $(this.#mapper.sortOption).on('change', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'sort', e.currentTarget.value, null, true);
        });

        $(this.#mapper.limit).on('change', e => {
            const elm = e.currentTarget;

            this.toggleFilter(elm, 'limit', e.currentTarget.value, null, true);
        });

        $(this.#mapper.priceRange).on('slidestop', (event, ui) => {

            const elm = $(`.selected-filter-btn[data-name="price"]`);
            const parentElm = $(event.currentTarget).closest('.priceslider');


            if (elm.length > 0) {
                elm.remove();
            }

            const priceRange = ui.values.join('-');

            this.toggleFilter(event, 'price', priceRange, priceRange, true, window.isMobile);

            parentElm[0].dataset.search = priceRange;
            parentElm[0].dataset.title = priceRange;
            $(parentElm[0]).data('search', priceRange);
            $(parentElm[0]).data('title', priceRange);
        });

        $(document).on('click', '.selected-filter-btn', e => {
            e.preventDefault();
            e.stopPropagation();

            const elm = e.currentTarget;
            const elmInFilter = $(`[data-search-name="${elm.dataset.name}"][data-search="${elm.dataset.value}"]`);

            this.toggleFilter(elmInFilter[0], elmInFilter.data('searchName'), elmInFilter.data('search'), elmInFilter.data('title'), false, window.isMobile);
        });

        if (true === isMobile) {
            $(this.#mapper.filterBtnOpen).on('click', e => {
                $('body').addClass('disable-scroll');
                $('#scrollUp').addClass('hide');
                document.getElementById("myNav").style.height = "100%";
            });

            $(this.#mapper.filterBtnClose).on('click', e => {
                $('body').removeClass('disable-scroll');
                $('#scrollUp').removeClass('hide');
                document.getElementById("myNav").style.height = "0%";
            })

            $(this.#mapper.applyFilter).on('click', e => {
                this.execute();
            });

            $(this.#mapper.resetFilter).on('click', e => {
                this.#paramService.removeAllParams();

                this.execute();
            });
        }
    }

    execute()
    {
        $('body').removeClass('disable-scroll');
        $('#scrollUp').removeClass('hide');

        this.#pageService.loadItems(1);

        this.closeFilterModal();
    }

    closeFilterModal()
    {
        $('.collapse').collapse('hide');

        const totalFilters = this.#paramService.getTotalCountFilters();

        $(this.#mapper.totalFilterCount).text(totalFilters > 0 ? `(${totalFilters})` : '');

        $(this.#mapper.filterBtnClose).click();
    }

    /**
     * @param {HTMLElement} e
     * @param {string} name
     * @param {string} value
     * @param {null|string} text
     * @param {boolean} onlyOne
     * @param {boolean} isMobile
     * @returns {Object[]}
     */
    toggleFilter(e, name, value, text, onlyOne, isMobile = false)
    {
        let selectedParams = this.#paramService.toggleParam(e, name, value, text, onlyOne, isMobile);

        if (false === isMobile) {
            this.#pageService.loadItems(1);
        }

        this.#dom.showLoadMoreButton();

        return selectedParams;
    }
}

export default FilterService;
