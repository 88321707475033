import filterMapper from "../../Mapper/FilterMapper";
import FilterDomService from "./FilterDomService";

class FilterParamService {
    constructor() {
        if (!FilterParamService.instance) {
            this.mapper = filterMapper;
            this.domService = new FilterDomService();
            this.params = {};

            FilterParamService.instance = this;
        }

        return FilterParamService.instance;
    }

    setInitParams()
    {
        for (let i = 0; i < FILTERS.length; i++) {
            let filter = FILTERS[i];

            this.params[filter] = [];
        }
    }

    addParams(key, values, isMobile, isOnlyOneFilterAtTime)
    {
        let elm = null;

        this.params[key] = values;

        for (const value of values) {
            elm = $(`[data-search-name="${key}"][data-search="${value}"]`);

            if (elm.length === 0) {
                continue;
            }

            this.domService.addSelectedFilter(
                elm.data('searchName'),
                elm.data('search'),
                elm.data('title'),
                isOnlyOneFilterAtTime,
                window.isMobile
            );
        }

        this.domService.totalParamsOnFilter(
            $(elm).closest(this.mapper.filterWidgetMobile),
            this.params[key].length
        );
    }

    /**
     *
     * @param {HTMLElement} elm
     * @param {string} paramName
     * @param {string} paramValue
     * @param {null|string} text
     * @param {boolean} onlyOne
     * @param {boolean} isMobile
     * @returns {Object[]}
     */
    toggleParam(
        elm,
        paramName,
        paramValue,
        text = null,
        onlyOne = false,
        isMobile = false
    ) {
        const valueIndex = this.params[paramName].indexOf(paramValue.toString());

        if (valueIndex > -1) {
            this.params[paramName].splice(valueIndex, 1);

            this.domService.removeFilter(paramName, paramValue);

            this.domService.totalParamsOnFilter(
                $(elm).closest(this.mapper.filterWidgetMobile),
                this.params[paramName].length
            );

            return this.params[paramName];
        }

        if (true === onlyOne) {
            this.params[paramName] = [];
        }

        if (null !== text) {
            this.domService.addSelectedFilter(paramName, paramValue, text, onlyOne, isMobile);
        }

        this.params[paramName].push(paramValue);

        this.domService.totalParamsOnFilter(
            $(elm).closest(this.mapper.filterWidgetMobile),
            this.params[paramName].length
        );

        return this.params[paramName];
    }

    removeAllParams()
    {
        for (const [key, values] of Object.entries(this.params)) {
            this.domService.removeAllCriteriaOnPageByName(key);
        }

        this.domService.resetTotalParamsOnFilter();

        this.setInitParams();
    }

    getParams()
    {
        return this.params;
    }

    getTotalCountFilters()
    {
        let count = 0;

        for (const [key, values] of Object.entries(this.params)) {
            count += this.params[key].length;

            if (key === 'categories') {
                count -= 1;
            }

        }

        return count;
    }
}

const filterParamService = new FilterParamService();

Object.freeze(filterParamService);

export default filterParamService;
