import CartMapper from "../Mapper/CartDropDownMapper";
import AppHelperService from "../../../js/Helper/AppHelperService";
import PriceHelperService from "../../../js/Helper/PriceHelperService";

class CartDropDownDom {
    #mapper;
    #priceService;
    
    constructor() {
        if (!CartDropDownDom.instance) {
            this.#mapper = CartMapper;
            this.#priceService = PriceHelperService;

            CartDropDownDom.instance = this;
        }

        return CartDropDownDom.instance;
    }

    setCartData(data) {
        $(this.#mapper.cartModal)
            .empty()
            .append(this.#generateProductList(data.products))
            .append(this.#generateTotal(data))
            .append(this.#generateButtons());

        $(this.#mapper.productLength).text(data.products.length);
    }

    #generateProductList(products) {
        let productHtml = '<div class="my-cart-products">';

        for (const product of products) {
            productHtml += `
                <div class="single-mcp clearfix single-product" data-id="${product.id }">
                    <div class="single-mcp-img">
                        <img src="${product.image_link}" alt="">
                    </div>
                    <div class="single-mcp-content">
                        <a class="mcp-product-name" href="#">${product.title} (${product.size})</a>
                        <p class="mcp-pro-quantity">
                            <span class="quantity-number" data-cart-quantity="${product.quantity}">${product.quantity}</span> x
                            <span class="mcp-pro-price" data-cart-price="{{ product.prices.price_euro.absolute }}">
                                <span>${product.prices.price_euro.current}</span>
                            </span>
                        </p>
                    </div>
                    <a href="#" class="mcp-pro-delete"><i class="fa fa-times"></i></a>
                </div>
            `
        }

        productHtml += '</div>';

        return productHtml;
    }

    #generateTotal(data) {
        return `
            <div class="my-cart-total-cost">
                <span class="letter-capitalize">${Translator.trans('cart.total_products', null, 'messages', LOCALE)}</span>
                <span class="cost">
                        <span class="cost__number_euro" data-cart-total="${data.total.price_euro.absolute}">${data.total.price_euro.current}</span>
                    </span>
            </div>
        `;
    }

    #generateButtons() {
        return `
            <div class="viewcart-checkout">
                <a class="viewcart letter-capitalize" href="${Routing.generate('site.cart_page')}">
                    ${Translator.trans('cart.view', null, 'messages', LOCALE)}
                </a>
                <a class="checkout letter-capitalize" href="${Routing.generate('site.checkout_page')}">
                    ${Translator.trans('cart.checkout', null, 'messages', LOCALE)}
                </a>
            </div>
        `;
    }
}

const instance = new CartDropDownDom();

Object.freeze(instance);

export default instance;
