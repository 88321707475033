import imageSliderMapper from "../Mapper/ImageSliderMapper";
import SliderService from "./SliderService";

class ImageSliderWithThumbs {
    constructor() {
        this.mapper = imageSliderMapper;
    }

    setImageSlider(images) {
        const fullImageWrapper = `${this.mapper.fullImageWrapper} ${this.mapper.sliders}`;
        const thumbImageWrapper = `${this.mapper.thumbImageWrapper} ${this.mapper.sliders}`;

        for (let i = 0; i < images.length; i++) {
            let image = images[i];

            let altTag = DEFAULT_ALT_TAG;

            if (image.hasOwnProperty('alt_tag')) {
                altTag = image.alt_tag;
            }

            $(fullImageWrapper).append(
                `<li>
                    <img src="${image.image_link}" alt="${altTag}">
                </li>`
            )

            if (!IS_MOBILE) {
                $(thumbImageWrapper).append(
                    ` <li>
                   <a href="#"><img src="${image.image_link_thumb}" alt="${altTag}"></a>
                </li>`
                )
            }
        }

        SliderService.setProductSlider($(this.mapper.thumbImageWrapper), $(this.mapper.fullImageWrapper));
    }
}

export default ImageSliderWithThumbs;