import 'webpack-jquery-ui';
import ShopPageRouting from "../Routing/ShopPageRouting";
import ShopPageService from "../Service/ShopPageService";
import shopPageDom from "../Dom/ShopPageDom";
import shopPageMapper from "../Mapper/ShopPageMapper";
import FilterService from "../Service/Filter/FilterService";

class ShopPageController {
    #mapper;
    #router;
    #shopService;
    #dom;
    #filterService;


    constructor() {
        this.#mapper = shopPageMapper;
        this.#router = new ShopPageRouting();
        this.#shopService = new ShopPageService();
        this.#dom = shopPageDom;

        this.#filterService = new FilterService(this.#router, this.#shopService);

        this.#filterService.showSelectedFiltersOnLoad(
            this.fixCategoriesInSearchCriteria(),
            ['categories']
        );

        this.registerEvents();
    }

    fixCategoriesInSearchCriteria()
    {
        let searchCriteria = SEARCH_CRITERIA;

        if(searchCriteria['categories'].length > 1) {
            let formattedCategories = [];
            for (const [index, value] of Object.entries(searchCriteria['categories'])) {
                if (MAIN_CATEGORY.slug === value) {
                    continue;
                }

                formattedCategories.push(`${MAIN_CATEGORY.slug}/${value}`);
            }

            searchCriteria['categories'] = formattedCategories;
        }

        return searchCriteria;
    }

    registerEvents() {
        $(this.#mapper.loadMore).on('click', e => {
            this.#shopService.loadItems();
        });

        $(window).scroll(() => {
            this.#shopService.loadOnPageScroll();
        });
    }
}

export default ShopPageController;
