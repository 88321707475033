class PopupService {
    constructor() {
        if (!PopupService.instance) {
            this.isPreviouslyOpened = {};
            PopupService.instance = this;
        }

        return PopupService.instance;
    }

    toggleDropDown(e, dropDownSelector) {
        e.stopPropagation();
        e.preventDefault();

        if (false === (dropDownSelector in this.isPreviouslyOpened)) {
            this.isPreviouslyOpened[dropDownSelector] = false;
        }

        $.each($('.open'), (index, element) => {
            $(element).fadeOut("slow");
            $(element).removeClass('open');

            let id = `#${element.id}`;

            if (id !== dropDownSelector) {
                this.isPreviouslyOpened[id] = false;
            }
        })

        $.each($('.active-list'), (index, element) => {
            $(element).removeClass('active-list');
        });

        if(false === this.isPreviouslyOpened[dropDownSelector]) {
            this.isPreviouslyOpened[dropDownSelector] = true;

            $(dropDownSelector).fadeIn("slow");
            $(dropDownSelector).addClass('open');
        } else {
            this.isPreviouslyOpened[dropDownSelector] = false;
        }
    }
}

const popupService = new PopupService();
Object.freeze(popupService);

export default popupService;
