class CheckoutPageMapper {
    constructor() {
        if (!CheckoutPageMapper.instance) {
            this.form = $('#checkout-form');
            this.email = $('#email');
            this.paymentType = '[name="payment_type"]';
            this.paymentOptions = '[name="payment_options"]';
            this.accountCreateChk = $('#createAccount');
            this.accountCreateError = $('#createAccountError');
            this.password = $('#checkoutPassword');
            this.btn = $('.checkout-page-button');
            this.countryOptions = '#country';
            this.products = '#product-list';
            this.shippingPriceText = '#shipping-price';
            this.euroShippingPriceText = '#euro-shipping-price';
            this.orderTotalPriceInput = '#order-total-price-input';
            this.orderTotalPrice = '#order-total-price';
            this.euroOrderTotalPrice = '#euro-order-total-price';

            this.promoCouponInput = '#promo_coupon';
            this.promoCouponBtn = '.promo-coupon-btn';
            this.promoCouponErrorText = '#coupon-error-text';
            this.promoCouponPrice = '.promo-coupon-discount';

            CheckoutPageMapper.instance = this;
        }

        return CheckoutPageMapper.instance;
    }
}

const checkoutPageMapper = new CheckoutPageMapper();

Object.freeze(checkoutPageMapper);

export default checkoutPageMapper;
