import owlCarouselService from "../Service/OwlCarouselService";

class HomePageController {
    constructor() {
        this.owlOptionsService = owlCarouselService;

        $('#top_carousel').owlCarousel(this.owlOptionsService.owlOptions());
        $('#bottom_carousel').owlCarousel(this.owlOptionsService.owlOptions());
    }
}

export default HomePageController;
