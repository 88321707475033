class CookieConsentMapper {
    constructor() {
        if (!CookieConsentMapper.instance) {
            this.modal = '#cookie-consent-modal';
            this.acceptBtn = '#accept-btn';
            this.chkAnalytics = '#analyticsCookie';

            CookieConsentMapper.instance = this;
        }

        return CookieConsentMapper.instance;
    }
}

const cookieConsentMapper = new CookieConsentMapper();

Object.freeze(cookieConsentMapper);

export default cookieConsentMapper;
