import AppHelperService from "../../../js/Helper/AppHelperService";
import CartDom from "../Dom/CartDropDownDom";
import cartPageMapper from "../Mapper/CartPageMapper";
import NotificationService from "../../../js/NotificationService";
import loader from "../Dom/LoaderDom";
import CartPageDom from "../Dom/CartPageDom";

class CartHandler {
    constructor() {
        this.cartDom = CartDom;
        this.pageMapper = cartPageMapper;
        this.notification = NotificationService();
        this.cartPageDom = CartPageDom;
    }

    update() {
        const data = [];
        $.each($(this.pageMapper.quatityInput), (index, elm) => {
            data.push({
                name: $(elm).attr('name'),
                value: $(elm).val(),
            })
        });

        $('#page-loader').fadeOut('slow', function() { $(this).removeClass('hide'); });

        $.ajax({
            type: 'PUT',
            url: AppHelperService.generateLocalizedUrl('site_api.update_order_products'),
            data,
            dataType: 'json',
            success: response => {
                this.cartPageDom.updateTotalWidget(response);
                $('#page-loader').addClass('hide');
            },
            error: error => {
                $('#page-loader').addClass('hide');
            }
        })
    }

    remove(id, elm) {
        let urlRoute = AppHelperService.generateLocalizedUrl('site_api.remove_order_product', {id});
        let type = 'DELETE';

        $('#page-loader').fadeOut('slow', function() { $(this).removeClass('hide'); });

        $.ajax({
            type,
            url: urlRoute,
            dataType: 'json',
            success: (response) => {
                this.cartDom.setCartData(response);

                if (elm) {
                    elm.remove();
                    this.cartPageDom.updateTotalWidget(response);
                }
                $('#page-loader').addClass('hide');
            },
            error: (error) => {
                $('#page-loader').addClass('hide');
            }
        })
    }

    setCoupon() {
        loader.show();
        this.pageMapper.promoCouponErrorText.empty();

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'PATCH',
                url: AppHelperService.generateLocalizedUrl('site_api.set_order_coupon', {code: this.pageMapper.promoCouponInput.val()}),
                dataType: 'json',
                success: response => {
                    window.DISCOUNT = response.discount;

                    resolve(response.discount);

                    loader.hide();
                },
                error: error => {
                    if (error.responseJSON.message) {
                        this.pageMapper.promoCouponErrorText.text(Translator.trans(error.responseJSON.message, null, 'validators', LOCALE));
                    } else {
                        this.pageMapper.promoCouponErrorText.text(Translator.trans('promo_coupon.not_found', null, 'validators', LOCALE));
                    }

                    loader.hide();

                    reject(error);
                }
            })
        })
    }
}

export default CartHandler;
