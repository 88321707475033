import AppHelperService from "./AppHelperService";

class PriceHelperService {
    convertPriceWithCurrency(price, $currency = 'EUR')
    {
        const calculatedPrices = {};

        if ('HRK' === $currency) {
            calculatedPrices.price_hrk = {
                'current': AppHelperService.formatAndConvertPrice(price, CURRENCY_RATE, DEFAULT_LOCALE),
                'absolute': AppHelperService.convertPrice(price, CURRENCY_RATE),
            };

            calculatedPrices.price_euro = {
                'current': AppHelperService.formatAndConvertPrice(price, EURO_CURRENCY_RATE, EURO_LOCALE),
                'absolute': AppHelperService.convertPrice(price, EURO_CURRENCY_RATE),
            };
        }

        if ('EUR' === $currency) {
            calculatedPrices.price_hrk = {
                'current': AppHelperService.formatAndConvertPrice(price, EURO_CURRENCY_RATE, LOCALE, true),
                'absolute': AppHelperService.convertToAbsolutePrice(price, EURO_CURRENCY_RATE, true),
            };

            calculatedPrices.price_euro = {
                'current': AppHelperService.formatAndConvertPrice(price, CURRENCY_RATE, EURO_LOCALE),
                'absolute': AppHelperService.convertToAbsolutePrice(price, CURRENCY_RATE),
            };
        }

        return calculatedPrices;
    }
}

export default new PriceHelperService();
