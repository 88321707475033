class ShopPageMapper {
    constructor() {
        if (!ShopPageMapper.instance) {
            this.category = $('.category-btn');
            this.color = $('.color-btn');
            this.size = $('.size-btn');
            this.sortOption = $('.sort-option');
            this.limit = $('.limit-product');
            this.searchView = $('.search-criteria');
            this.selectedCriteria = $('.selected-filter-btn');
            this.priceRange = $('#shop-slider-range');
            this.amountPrice = $('#shop-amount');
            this.loadMore = '#load-more';
            this.noMoreItemsText = '#no-more-items-text';

            ShopPageMapper.instance = this;
        }

        return ShopPageMapper.instance;
    }
}

const shopPageMapper = new ShopPageMapper();

Object.freeze(shopPageMapper);

export default shopPageMapper;
