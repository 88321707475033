import AppHelperService from "../../../js/Helper/AppHelperService";

class CheckoutFinishPageController {
    constructor() {
        this.registerEvents();
    }

    registerEvents() {
        // setTimeout(
        //     () => {
        //         AppHelperService.redirect(Routing.generate('site.home_page'))
        //     },
        //     60000
        // )
    }
}

export default CheckoutFinishPageController;
