import CookieConsent from "../Service/Cookie/CookieConsent";
import cookieManipulationService from "../Service/Cookie/CookieManipulationService";

class GoogleAnalyticsProvider {
    constructor() {
        if (!GoogleAnalyticsProvider.instance) {
            this.cookieManipulator = cookieManipulationService;

            GoogleAnalyticsProvider.instance = this;
        }

        return GoogleAnalyticsProvider. instance;
    }
    init() {
        if (
            null === this.cookieManipulator.getCookie(CookieConsent.settings.name) ||
            'dev' === ENVIRONMENT
        ) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', GOOGLE_ANALYTICS_API_KEY);


        // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        //     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        // })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
        //
        // ga('create', GOOGLE_ANALYTICS_API_KEY, 'auto');
        // ga('send', 'pageview');
    }
}

const googleAnalyticsProvider = new GoogleAnalyticsProvider();

Object.freeze(googleAnalyticsProvider);

export default googleAnalyticsProvider;
