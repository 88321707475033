import checkoutPageMapper from "../Mapper/CheckoutPageMapper";
import AppHelperService from "../../../js/Helper/AppHelperService";
import FormHelperService from "../../../js/Helper/FormHelperService";
import loader from "../Dom/LoaderDom";
import {sha512} from "js-sha512";
import NotificationService from "../../../js/NotificationService";

require('jquery.redirect');

class CheckoutHandler {
    constructor() {
        this.mapper = checkoutPageMapper;
        this.notification = NotificationService();
    }

    save() {
        if (IS_READY_FOR_COMPLETE === 1) {
            this.notification.show('error', Translator.trans(`cart.empty`, null, 'messages', LOCALE), true);

            return;
        }

        grecaptcha.ready(() => {
            grecaptcha.execute(GOOGLE_RECAPTCHA_KEY_SITE, {action: 'complete_order'}).then((token) => {
                const data = this.mapper.form.serializeArray();

                data.push({
                    name: 'recaptcha_response',
                    value: token
                });

                if (! this.mapper.form.valid()) {
                    return false;
                }

                loader.show();

                $.ajax({
                    type: 'PUT',
                    url: Routing.generate(`site_api.complete_order.${LOCALE}`),
                    data: FormHelperService.sanitize(data),
                    dataType: 'json',
                    success: response => {
                        if (PAYMENT_TYPES[response.order.payment_type].redirect_to_bank === 1 ) {
                            AppHelperService.redirect(Routing.generate(`site.checkout_completed_successful.${LOCALE}`));

                            return;
                        }

                        $.redirect(
                            window[response.order.payment_type.toUpperCase()].url,
                            response.bank_transfer
                        )
                    },
                    error: error => {
                        loader.hide();
                    }
                })
            });
        });
    }

    setCoupon() {
        loader.show();
        $(this.mapper.promoCouponErrorText).empty();

        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'PATCH',
                url: AppHelperService.generateLocalizedUrl('site_api.set_order_coupon', {code: $(this.mapper.promoCouponInput).val()}),
                dataType: 'json',
                success: response => {
                    window.DISCOUNT = response.discount;
                    window.DISCOUNT_ELIGIBLE_DISCOUNTED_ITEMS = true === response.is_eligible_discounted_items ? 1 : 0;

                    resolve(response.discount);

                    loader.hide();
                },
                error: error => {
                    if (error.responseJSON.message) {
                        $(this.mapper.promoCouponErrorText).text(Translator.trans(error.responseJSON.message, null, 'validators', LOCALE));
                    } else {
                        $(this.mapper.promoCouponErrorText).text(Translator.trans('promo_coupon.not_found', null, 'validators', LOCALE));
                    }

                    loader.hide();

                    reject(error);
                }
            })
        })
    }
}

export default CheckoutHandler;
