class FilterMapper {
    constructor() {
        if(!FilterMapper.instance) {
            this.category = '.category-btn';
            this.color = '.color-btn';
            this.size = '.size-btn';
            this.heel = '.heel-btn';
            this.discount = '.discount-btn';
            this.priceRange = '#shop-slider-range';
            this.searchView = '.search-criteria';
            this.selectedCriteriaFilter = '.filter-selected';
            this.filterWidget = '.sidebar-widget';
            this.filterWidgetMobile = '.filter-content-widget';
            this.totalSelectedFilter = '.total-params';
            this.optionBtn = '.filter-option';
            this.applyFilter = '.apply-filter';
            this.resetFilter = '.reset-filter';
            this.filterBtnOpen = '#filter-btn-open';
            this.filterBtnClose = '#filter-btn-close';
            this.totalFilterCount = '.total-selected-filters';
            this.sortOption = '.sort-option';
            this.limit = '.limit-product';

            FilterMapper.instance = this;

        }

        return FilterMapper.instance;
    }
}

const filterMapper = new FilterMapper();

Object.freeze(filterMapper);

export default filterMapper;
