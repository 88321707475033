class CookieManipulationService {
    constructor() {
        if (!CookieManipulationService.instance) {
            CookieManipulationService.instance = this;
        }

        return CookieManipulationService.instance;
    }

    setCookie(name, expires, value) {
        const d = new Date();

        d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${d.toUTCString()};path=/`;

        return this.getCookie(name);
    }

    /**
     *
     * @param name
     * @return {JSON|null}
     */
    getCookie(name) {
        const ca = decodeURIComponent(document.cookie).split("; ");
        name += "=";

        const values = ca.find(cookie => cookie.startsWith(name))?.split('=')[1];

        return undefined !== values ? JSON.parse(values) : null;
    }
}

const cookieManipulationService = new CookieManipulationService();

Object.freeze(cookieManipulationService);

export default cookieManipulationService;
