import shopPageMapper from "../Mapper/ShopPageMapper";

class PaginationDom {
    constructor() {
        if (!PaginationDom.instance) {
            this.mapper = shopPageMapper;

            PaginationDom.instance = this;
        }

        return PaginationDom.instance;
    }

    /**
     *
     * @param {object} data
     * @returns {void}
     */
    generate(data) {
        const currentPage = data.currentPage;

        let pages = ``;
        let i = data.totalPages;
        let firstDomPage = currentPage - 3 > 0 ? currentPage - 3 : 1;
        let lastDomPage = currentPage + 3 <= data.totalPages ? currentPage + 3 : data.totalPages;
        let nextPage = currentPage + 1;

        $('.pagination .pages').remove();

        this.toggleButton('.pagination .first', !data.disableFirst);
        $('.pagination .first a').attr('href', this.generateUrl(1));

        this.toggleButton('.pagination .prev', !data.disableFirst);
        $('.pagination .prev a').attr('href', this.generateUrl(currentPage - 1));

        this.toggleButton('.pagination .next', !data.disableLast);
        $('.pagination .next a').attr('href', this.generateUrl(currentPage + 1));

        this.toggleButton('.pagination .last', !data.disableLast);
        $('.pagination .last a').attr('href', this.generateUrl(data.totalPages))

        while (firstDomPage < currentPage) {
            pages += this.generatePage(firstDomPage);

            firstDomPage++;
        }

        pages += this.generatePage(currentPage, true);

        while (nextPage <= lastDomPage) {
            pages += this.generatePage(nextPage);

            nextPage++;
        }

        $(pages).insertAfter('.prev');
    };

    /**
     * @param {number} pageNumber
     * @param {boolean} isActive
     * @returns {*|jQuery|HTMLElement}
     */
    generatePage(pageNumber, isActive = false)
    {
        let url = this.generateUrl(pageNumber);
        return `<li class="pages ${isActive ? 'active' : ''}"><a href="${url}">${pageNumber}</a></li>`;
    }

    /**
     * @param {number} pageNumber
     * @returns {string}
     */
    generateUrl(pageNumber)
    {
        return location.href.replace(/\/1/g, '/'+pageNumber);
    }

    toggleButton(selector, isEnabled)
    {
        if (isEnabled) {
            $(selector).removeClass('disabled');
        } else {
            $(selector).addClass('disabled');
        }
    }
}

const paginationDom = new PaginationDom();
Object.freeze(paginationDom);

export default paginationDom;
