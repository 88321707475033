import BaseCoreController from "../../js/CoreController";
import CartHandler from "./Handler/CartHandler";
import loader from "./Dom/LoaderDom";
import coreMapper from "./Mapper/CoreMapper";
import UserHandler from "./Handler/UserHandler";
import registrationValidator from "./Validators/RegistrationValidator";
import WishListHandler from "./Handler/WishListHandler";
import NewsLetterHandler from "./Handler/NewsLetterHandler";
import resetPasswordValidator from "./Validators/ResetPasswordValidator";
import CookieConsent from "./Service/Cookie/CookieConsent";
import googleAnalyticsProvider from "./Providers/GoogleAnalyticsProvider";

require('gdpr-cookie/gdpr-cookie');

class CoreController {
    constructor() {
        this.baseCore = new BaseCoreController();
        this.handler = new CartHandler();
        this.mapper = coreMapper;
        this.registrationValidator = registrationValidator;
        this.resetPasswordValidator = resetPasswordValidator;
        this.cookieConsent = new CookieConsent();
        this.googleAnalytics = googleAnalyticsProvider;

        loader;

        this.sliderText();

        $('nav#dropdown').meanmenu();

        this.registerEvents();

        this.cookieConsent.init();

        this.googleAnalytics.init();
    }

    siteMobileMenu() {
        $('nav#mobile_menu_active').meanmenu({
            meanScreenWidth: "991",
            meanMenuContainer: '.mobile-menu-area .mobile_menu',
            meanMenuOpen: "<span></span><span></span><span></span>",
            meanRevealPosition: "left",
        });

        const searchButton = '<button class="search-opener-mobile search-opener hvr-bs"><i class="fa fa-search"></i></button>';

        let languageHtml = `<div class="language text-center">
                                <a id="current_language" href="#" class="text-uppercase">${LANGUAGES[LOCALE].code}</a>
                                <ul id="locale-dropdown" class="menu-right-dropdown">
        `;

        for (const [locale, languageData] of Object.entries(TRANS_URLS)) {
            if (LOCALE != locale) {
                languageHtml += `<li>
                                    <a href="${languageData.url}" class="text-uppercase">${languageData.code}</a>
                                </li>`;
            }
        }

        languageHtml += '</ul></div>';

        $(`<div class="search-lang-bar pull-right">${searchButton}${languageHtml}</div>`)
            .insertAfter(`${this.mapper.meanMenuContainer} ${this.mapper.meanMenuContainerReveal}`);
    }

    sliderText() {
        $('#slider_text_carousel').owlCarousel({
            loop: true,
            margin: 0,
            responsiveClass: true,
            navigation: false,
            navText: false,
            nav: false,
            items: 1,
            smartSpeed: 2000,
            dots: false,
            autoplay: false,
            autoplayTimeout: 4000,
            center: false,
            responsive: {
                0: {
                    items: 1,
                    autoplay: true,
                },
                480: {
                    items: 1,
                    autoplay: true,
                },
                760: {
                    items: 3,
                    autoplay: true,
                }
            }
        });
    }

    registerEvents() {
        $(document).on('click', '.top-cart .mcp-pro-delete', e => {
            e.preventDefault();
            e.stopPropagation();

            const productId = $(e.currentTarget).parent('.single-product').data('id');

            this.handler.remove(productId);
        });

        $(document).on('click', this.mapper.registrationBtn, e => {
            const handler = new UserHandler();

            this.registrationValidator.validate(this.mapper.registrationForm);

            $(this.mapper.registrationForm).valid();

            handler.doRegistration(this.mapper.registrationForm);
        });

        $(document).on('click', this.mapper.loginBtn, e => {
            const handler = new UserHandler();

            handler.doLogin(this.mapper);
        });

        $(document).on('click', this.mapper.toggleWishListBtn, e => {
            e.preventDefault();
            e.stopPropagation();

            const handler = new WishListHandler();

            handler.toggle($(e.currentTarget));
        });

        $(document).on('click', this.mapper.newsLetterSubmitBtn, e => {
            e.preventDefault();
            e.stopPropagation();

            const handler = new NewsLetterHandler();

            handler.addUser(this.mapper.newsLetterForm, true);
        });

        $(document).on('click', this.mapper.newsLetterSubmitBtnFooter, e => {
            e.preventDefault();
            e.stopPropagation();

            const handler = new NewsLetterHandler();

            handler.addUser(this.mapper.newsLetterFormFooter);
        });

        $(document).on('click', '#current_language',  e => {
            e.preventDefault();
            e.stopPropagation();

            const listElm = $(e.target).next();

            if (!$(listElm).hasClass('active-list')) {
                $(listElm).addClass('active-list');

                return;
            }

            $(listElm).removeClass('active-list');
        });

        $('#reset_password_btn').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            $('.lrc-login').fadeOut();
            $('.lrc-register').fadeOut();
            $('#reset_password').fadeOut();

            $('#reset_password_form_wrapper').fadeIn();
            $('#login_register_show').fadeIn();
        });

        $('#login_register_show_btn').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            $('.lrc-login').fadeIn();
            $('.lrc-register').fadeIn();
            $('#reset_password').fadeIn();

            $('#reset_password_form_wrapper').fadeOut();
            $('#login_register_show').fadeOut();
        });

        $(document).on('click', this.mapper.resetPasswordBtn, e => {
            const handler = new UserHandler();

            this.resetPasswordValidator.validate(this.mapper.resetForm);

            $(this.mapper.resetForm).valid();

            handler.doResetPassword(this.mapper);
        });

        $(document).on('click', this.mapper.searchOpener, e => {
            e.preventDefault();
            e.stopPropagation();

            if (!$(this.mapper.searchArea).hasClass('show')) {
                $(this.mapper.searchArea).fadeIn(500);
                $(this.mapper.searchArea).addClass('show');
            }
        })

        $(document).on('click', this.mapper.searchClose, e => {
            e.preventDefault();
            e.stopPropagation();

            if ($(this.mapper.searchArea).hasClass('show')) {
                $(this.mapper.searchArea).fadeOut(500);
                $(this.mapper.searchArea).removeClass('show');
            }
        })

        $(document).on('submit', this.mapper.searchForm, e => {
            e.preventDefault();
            e.stopPropagation();

            location.href = Routing.generate(`site.shop_page.${LOCALE}`) +
                `/1/${Translator.trans(
                    'search',
                    null,
                    'messages',
                    LOCALE
                )}/${$(this.mapper.searchInput).val()}`;
        })

        $('.open-cookie-consent-modal').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.cookieConsent.openModal();
        });
    }
}

export default CoreController;
