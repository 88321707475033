class CartPageMapper {
    constructor() {
        if (!CartPageMapper.instance) {
            this.quatityInput = '.product-quantity-t input';
            this.productPriceHrk = '.product_price_hrk';
            this.productPriceEuro = '.product_price_euro';
            this.productTotalPriceEuro = '.product_price_total_euro';
            this.productTotalPriceHrk = '.product_price_total_hrk';
            this.removeProduct = $('.remove-product');
            this.total = $('.total-price');
            this.totalEuro = $('.total-price-euro');
            this.totalShipping = $('.total-price-shipping');
            this.euroTotalShipping = $('.euro-total-price-shipping');
            this.updateBtn = $('.scb-update');
            // this.promoCouponInput = $('#promo_coupon');
            // this.promoCouponBtn = $('.promo-coupon-btn');
            // this.promoCouponErrorText = $('#coupon-error-text');
            this.promoCouponPrice = $('.promo-coupon-discount');
            this.promoCouponHolder = $('.promo-coupon-holder');
            this.shippingPrice = $('.shipping-price');
            this.euroShippingPrice = $('.euro-shipping-price');
            this.countryOptions = '#country';
            this.paymentOptions = '#payment';

            CartPageMapper.instance = this;
        }

        return CartPageMapper.instance;
    }
}

const cartPageMapper = new CartPageMapper();

Object.freeze(cartPageMapper);

export default cartPageMapper;
