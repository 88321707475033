import checkoutPageMapper from "../Mapper/CheckoutPageMapper";
import CheckoutHandler from "../Handler/CheckoutHandler";
import checkoutValidation from "../Validators/CheckoutValidation";
import UserService from "../Service/UserService";
import RecaptchaLoader from "../../../js/Services/RecaptchaLoader";
import CheckoutPageDom from "../Dom/CheckoutPageDom";

class CheckoutPageController {
    constructor() {
        this.mapper = checkoutPageMapper;
        this.handler = new CheckoutHandler();
        this.validator = checkoutValidation;
        this.userService = new UserService();
        this.pageDom = new CheckoutPageDom();

        RecaptchaLoader.loadRecaptcha();

        this.validator.validate();

        this.registerEvents();
    }

    registerEvents() {
        this.mapper.form.on('submit', e => {
            e.preventDefault();
            e.stopPropagation();

            this.handler.save();
        });

        $('.open-login').on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            $('#login_register').click();
        })

        this.mapper.accountCreateChk.on('click', e => {
            this.mapper.accountCreateError.fadeOut();
            const email = this.mapper.email.val();

            if (this.mapper.accountCreateChk.is(':checked') && email.length > 0) {

                this.userService.isUserExistsByEmail(email)
                    .then(response => {
                        this.mapper.password.removeAttr('disabled');
                    })
                    .fail(error => {
                        let message = 'checkout.account_exists';

                        if (error.status === 403) {
                            message = 'checkout.account_deactivated';
                        }

                        this.mapper.accountCreateError.fadeIn();
                        this.mapper.accountCreateError.text(Translator.trans(message, {'email': email}, 'messages', LOCALE));
                        this.mapper.accountCreateChk.prop('checked', false);
                    });


                return;
            }

            this.mapper.password.val('');
            this.mapper.password.attr('disabled', 'disabled');
            this.mapper.accountCreateChk.prop('checked', false);
        });

        this.mapper.email.on('keyup', e => {
            this.mapper.accountCreateError.fadeOut();
        });

        $(this.mapper.countryOptions).on('change', e => {
            this.pageDom.uncheckAndDisablePaymentsByCountry();
            this.pageDom.updateOrderPrice()
        });

        $(this.mapper.paymentType).on('change', e => {
            this.pageDom.updateOrderPrice();
        });

        $(this.mapper.promoCouponBtn).on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.handler.setCoupon()
                .then(response => {
                    this.pageDom.updateOrderPrice();
                })
        });

        $(this.mapper.paymentOptions).on('change', e => {
            const checked = $(e.currentTarget).val();

            if (PAYMENT_OPTIONS_CREATE_ACCOUNT === checked) {
                $('.cbab-account-password').removeClass('hide');

                return;
            }

            if (PAYMENT_OPTIONS_LOGIN === checked) {
                $('#scrollUp').click();
                $('#login_register').click();
            }

            if (!$('.cbab-account-password').hasClass('hide')) {
                $('.cbab-account-password').addClass('hide');
            }
        });
    }
}

export default CheckoutPageController;
