import ProductPageHandler from "../Handler/ProductPageHandler";
import Tipped from "@staaky/tipped";
require('@fancyapps/fancybox');

import ProductPageMapper from "../Mapper/ProductPageMapper";
import ProductPageService from "../Service/ProductPageService";
import SliderService from "../Service/SliderService";
import owlCarouselService from "../Service/OwlCarouselService";

class ProductPageController {
    constructor() {
        this.mapper = ProductPageMapper;
        this.service = new ProductPageService();
        this.handler = new ProductPageHandler();
        this.owlOptionsService = owlCarouselService;

        // this.service.generateImageSlider();
        SliderService.setProductSlider();
        Tipped.create('.cleaning-icons');

        $('.related-pro-carousel').owlCarousel(this.owlOptionsService.owlOptions());

        this.registerEvents();
    }

    registerEvents() {
        this.mapper.color.on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.service.showImagesByColor($(e.currentTarget));
        });

        this.mapper.size.on('click', e => {
            e.preventDefault();
            e.stopPropagation();

            this.service.toggleActiveSize(e.currentTarget);
        });

        this.mapper.addBtn.on('click', e => {
            this.handler.save();
        });
    }
}

export default ProductPageController;
