class OwlCarouselService {
    owlOptions() {
        return {
            loop: true,
            margin: 0,
            responsiveClass: true,
            navigation: true,
            navText: ["<i class='fa fa-long-arrow-left'></i>", "<i class='fa fa-long-arrow-right'></i>"],
            nav: true,
            items: 1,
            smartSpeed: 2000,
            dots: false,
            autoplay: true,
            autoplayTimeout: 4000,
            center: false,
            responsive: {
                0: {
                    items: 1,
                    center: true,
                    margin: 20,
                    stagePadding: 20,
                },
                480: {
                    items: 1,
                    center: true,
                },
                760: {
                    items: 3
                }
            }
        };
    }
}

const owlCarouselService = new OwlCarouselService();

Object.freeze(owlCarouselService);

export default owlCarouselService;
