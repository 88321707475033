import myAccountPageMapper from "../Mapper/MyAccountPageMapper";
import MyAccountService from "../Service/MyAccountService";
import UserHandler from "../Handler/UserHandler";
import Tipped from "@staaky/tipped";

class MyAccountPageController {
    constructor() {
        this.mapper = myAccountPageMapper;
        this.service = new MyAccountService();
        this.handler = new UserHandler();

        Tipped.create('.cart_download_invoice a');

        this.registerEvents();
    }

    registerEvents() {
        $(this.mapper.personalBtn).on('click', e => {
            this.handler.doUpdate(this.mapper.personalForm);
        });
        // $(this.mapper.orderTab).on('show.bs.tab', e => {
        //     this.service.getList();
        // })
    }
}

export default MyAccountPageController
