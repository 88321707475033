class ImageSliderMapper {
    constructor() {
        if(!ImageSliderMapper.instance) {
            this.fullImageWrapper = '#sliders';
            this.thumbImageWrapper = '#carousel';
            this.sliders = '.slides';
            this.imagesWrapper = '#images-wrapper';

            ImageSliderMapper.instance = this;
        }

        return ImageSliderMapper.instance;
    }
}

const imageSliderMapper = new ImageSliderMapper();

Object.freeze(imageSliderMapper);

export default imageSliderMapper;